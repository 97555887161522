interface Environment {
    apiUrl: string,
    adminRoute: string,
    liveTournamentId?: number,
    liveTournamentIdInt?: number,
    liveTournamentIdComp?: number,
    summerLeagueId: number,
    fallLeagueId: number,
    prod: boolean,
}

const environmentDev: Environment = {
    apiUrl: 'http://localhost:4000',
    adminRoute: 'admin',
    liveTournamentId: 2,
    liveTournamentIdInt: undefined,
    liveTournamentIdComp: undefined,
    summerLeagueId: 1,
    fallLeagueId: 1,
    prod: false
}

const environmentProd: Environment = {
    apiUrl: 'https://bumpin-backend.bumpin.projects-backend.com',
    adminRoute: 'hmzkegdahfzfclra',
    liveTournamentId: 17,
    liveTournamentIdInt: undefined,
    liveTournamentIdComp: undefined,
    summerLeagueId: 1,
    fallLeagueId: 2,
    prod: true
}

let environment: Environment;

if (window.location.host.includes('localhost')) {
    environment = environmentDev
} else {
    environment = environmentProd
}

export default environment;