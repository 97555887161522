import { ChakraProvider } from "@chakra-ui/react"
import Landing from "./scenes/landing/Landing"
import theme from "./theme";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AdminTournament from "./scenes/admin/AdminTournament";
import TournamentManage from "./scenes/tournament/TournamentManage";
import Environment from "./utils/environment";
import ReactGA from "react-ga4";
import {useEffect, useState} from "react";
import {User} from "./models/Models";
import Cookies from "js-cookie";
import {UserContext} from "./hooks/context";
import NotFound from "./scenes/landing/NotFound";
import Login from "./scenes/user/Login";
import TeamHome from "./scenes/team/TeamHome";

import UserAPI from "./api/userApi";
import Root from "./components/Root";
import AdminLeague from "./scenes/admin/AdminLeague";
import LeagueHome from "./scenes/league/LeagueHome";
import RosterHome from "./scenes/roster/RosterHome";
import TournamentDisplay from "./scenes/tournament/TournamentDisplay";
import GameHome from "./scenes/game/GameHome";
import PlayerHome from "./scenes/player/PlayerHome";
import PlayerLeagueHome from "./scenes/player/PlayerLeagueHome";

const adminRoute = Environment.adminRoute

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Landing />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: `/${adminRoute}/tournament`,
        element: <AdminTournament />
      },
      {
        path: `/${adminRoute}/league`,
        element: <AdminLeague />
      },
      {
        path: `/${adminRoute}/tournament/:tournamentId/manage`,
        element: <TournamentManage />
      },
      {
        path: `/${adminRoute}/league/:leagueId/manage`,
        element: <LeagueHome />
      },
      {
        path: "/live",
        element: <TournamentDisplay tournamentId={Environment.liveTournamentId} />
      },
      // {
      //   path: "/live/comp",
      //   element: <Navigate to={'/live'} />,
      // },
      // {
      //   path: "/live/int",
      //   element: <Navigate to={'/live'} />,
      // },
      {
        path: "/team/:teamId",
        element: <TeamHome />
      },
      {
        path: "/league/summer-2024",
        element: <LeagueHome leagueId={Environment.summerLeagueId} />
      },
      {
        path: "/league/fall-2024",
        element: <LeagueHome leagueId={Environment.fallLeagueId} />
      },
      {
        path: "/roster/:rosterId",
        element: <RosterHome />
      },
      {
        path: "/game/:gameId",
        element: <GameHome />
      },
      {
        path: "/player/:playerId",
        element: <PlayerHome />
      },
      {
        path: "/player/:playerId/league/:leagueId",
        element: <PlayerLeagueHome />
      },
      {
        path: "/*",
        element: <NotFound />
      }
    ],
  },
]);

ReactGA.initialize("G-64L2E0YBND")

export const App = () => {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname
    })
    const accessToken = Cookies.get('accessToken')
    if (accessToken) {
      UserAPI
          .authenticateUser()
          .then(res => setUser(res.user))
    }
  }, [])

  return (
      <ChakraProvider theme={theme}>
        <UserContext.Provider
            value={{user, setUser}}
        >
          <RouterProvider router={router} />
        </UserContext.Provider>
      </ChakraProvider>
  )
}

