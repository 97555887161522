import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Select,
    useDisclosure
} from "@chakra-ui/react";
import SubmitMatchForm from "./SubmitMatchForm";
import {Match, Pool} from "../../models/Models";
import {useState} from "react";

import {stringToTime} from "../../utils/datetimeUtils";

interface SubmitPoolMatchesProps {
    matches: Match[]
    pools: Pool[]
}

const SubmitPoolMatches = (props: SubmitPoolMatchesProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [match, setMatch] = useState<Match | null>(null)
    const [pool, setPool] = useState<Pool>(Pool.A)

    const matchOptions = props.matches
        .filter(m => m.gameType === "POOL" && m.state === "SCHEDULED" && m.teamA.pool === pool)
        .sort((a, b) => {
            if (a.startsAt && b.startsAt) {
                const at = a.startsAt
                const bt = b.startsAt
                if (at < bt) return -1
                if (at === bt) return 0
                return 1
            }
            return  a.teamA.poolIndex - b.teamA.poolIndex
        })
        .map(m => (
            <option value={m.id} key={m.id}>
                [{stringToTime(m?.startsAt)}] ({m.teamA.pool}{m.teamA.poolIndex}) {m.teamA.name} vs ({m.teamB.pool}{m.teamB.poolIndex}) {m.teamB.name}
            </option>
        ))
    const poolOptions = props.pools.map(curPool =>
        <option value={curPool} key={curPool}>Pool {curPool}</option>
    )

    const onChooseMatch = (newMatchId: number) => {
        let match = props.matches.find(m => m.id === newMatchId)
        setMatch(match as Match)
    }

    return (
        <div>
            <Button onClick={onOpen}>
                Submit Match Score
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Submit Match Score</ModalHeader>
                    <ModalBody>
                        <Select placeholder='Choose Pool' value={pool}
                                onChange={event => setPool(event.target.value as Pool)}>
                            {poolOptions}
                        </Select>
                        <br />
                        {pool &&
                            <Select placeholder='Choose Match' value={match?.id}
                                    onChange={event => onChooseMatch(Number(event.target.value))}>
                                {matchOptions}
                            </Select>
                        }
                        <br />
                        {match && <SubmitMatchForm match={match} submitCallBack={onClose}/>}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )

}

export default SubmitPoolMatches